// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package dev.fritz2.kitchensink.formControl

import dev.fritz2.lenses.Lens
import dev.fritz2.lenses.buildLens
import kotlin.Boolean
import kotlin.String
import kotlin.collections.List

public fun Account.Companion.username(): Lens<Account, String> = buildLens(
    "username", 
    { it.username }, 
    { p, v -> p.copy(username = v)}
  )

public fun Account.Companion.passphrase(): Lens<Account, String> = buildLens(
    "passphrase", 
    { it.passphrase }, 
    { p, v -> p.copy(passphrase = v)}
  )

public fun Account.Companion.interests(): Lens<Account, List<String>> = buildLens(
    "interests", 
    { it.interests }, 
    { p, v -> p.copy(interests = v)}
  )

public fun Account.Companion.confirmation(): Lens<Account, Boolean> = buildLens(
    "confirmation", 
    { it.confirmation }, 
    { p, v -> p.copy(confirmation = v)}
  )
