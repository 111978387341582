// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package dev.fritz2.kitchensink.datatable

import dev.fritz2.lenses.Lens
import dev.fritz2.lenses.buildLens
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.datetime.LocalDate

public fun FinalPerson.Companion.id(): Lens<FinalPerson, Int> = buildLens(
    "id", 
    { it.id }, 
    { p, v -> p.copy(id = v)}
  )

public fun FinalPerson.Companion.firstname(): Lens<FinalPerson, String> = buildLens(
    "firstname", 
    { it.firstname }, 
    { p, v -> p.copy(firstname = v)}
  )

public fun FinalPerson.Companion.lastname(): Lens<FinalPerson, String> = buildLens(
    "lastname", 
    { it.lastname }, 
    { p, v -> p.copy(lastname = v)}
  )

public fun FinalPerson.Companion.birthday(): Lens<FinalPerson, LocalDate> = buildLens(
    "birthday", 
    { it.birthday }, 
    { p, v -> p.copy(birthday = v)}
  )

public fun FinalPerson.Companion.languages(): Lens<FinalPerson, List<String>> = buildLens(
    "languages", 
    { it.languages }, 
    { p, v -> p.copy(languages = v)}
  )
