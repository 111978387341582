// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package dev.fritz2.kitchensink.datatable

import dev.fritz2.lenses.Lens
import dev.fritz2.lenses.buildLens
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public fun Person.Companion.id(): Lens<Person, Int> = buildLens(
    "id", 
    { it.id }, 
    { p, v -> p.copy(id = v)}
  )

public fun Person.Companion.firstname(): Lens<Person, String> = buildLens(
    "firstname", 
    { it.firstname }, 
    { p, v -> p.copy(firstname = v)}
  )

public fun Person.Companion.lastname(): Lens<Person, String> = buildLens(
    "lastname", 
    { it.lastname }, 
    { p, v -> p.copy(lastname = v)}
  )

public fun Person.Companion.birthday(): Lens<Person, String> = buildLens(
    "birthday", 
    { it.birthday }, 
    { p, v -> p.copy(birthday = v)}
  )

public fun Person.Companion.language(): Lens<Person, List<String>> = buildLens(
    "language", 
    { it.language }, 
    { p, v -> p.copy(language = v)}
  )
